<template>
  <div>
    <div class="kt-login__head">
      <div
        class="kt-header__topbar d-flex align-items-center justify-content-end cus-topbar"
      >
        <span>{{ $t("TRANSLATOR.SELECT") }}</span>
        <!--begin: Language bar -->
        <div class="kt-header__topbar-item kt-header__topbar-item--langs">
          <div
            class="kt-header__topbar-wrapper"
            id="kt_language_toggle"
            data-toggle="dropdown"
          >
            <span class="kt-header__topbar-icon">
              <img :src="languageFlag || getLanguageFlag" alt="" />
            </span>
          </div>
          <div
            class="dropdown-menu dropdown-menu-fit dropdown-menu-right"
            v-on:click.stop
          >
            <KTDropdownLanguage
              v-on:language-changed="onLanguageChanged"
            ></KTDropdownLanguage>
          </div>
        </div>
        <!--end: Language bar -->
      </div>
    </div>
    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>{{ $t("AUTH.LOGIN.TITLE") }}</h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <div
            role="alert"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>

          <b-form-group
            id="login-email-group"
            :label="$t('COMMON.USERNAME')"
            label-for="login-email"
          >
            <b-form-input
              id="login-email"
              name="login-email"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              {{ $t("AUTH.VALIDATION.EMAIL") }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="login-password-group"
            :label="$t('AUTH.INPUT.PASSWORD')"
            label-for="login-password"
            class="mt-4"
          >
            <b-form-input
              type="password"
              id="login-password"
              name="login-password"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-2-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-2-live-feedback">
              {{ $t("AUTH.VALIDATION.PASSWORD") }}
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <a
              href="#"
              class="kt-link kt-login__link-forgot"
              v-b-modal.forgot-modal
            >
              {{ $t("AUTH.FORGOT.TITLE") }}
            </a>
            <b-button
              type="submit"
              id="kt_submit"
              class="btn btn-primary btn-elevate kt-login__btn-primary"
            >
              {{ $t("AUTH.LOGIN.BUTTON") }}
            </b-button>

            <b-button
              v-on:click="resetForm"
              class="btn btn-primary btn-elevate kt-login__btn-primary"
            >
              {{ $t("AUTH.RESET") }}
            </b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
      <!-- ----------- twofa modal -->
      <b-modal
        id="twofa-modal"
        ref="twofa"
        :title="$t('TWOFA.TFAUTH')"
      >
        <b-container fluid>
          <b-alert show variant="info">
            {{ $t("AUTH.LOGIN.DESC4") }}
          </b-alert>
          <b-alert show variant="secondary">
            {{ $t("AUTH.LOGIN.DESC3") }}
          </b-alert>
          <b-row class="my-3">
            <b-col sm="5">
              <label for="logintwofa">{{ $t("AUTH.LOGIN.TFA") }}</label>
            </b-col>
            <b-col sm="7">
              <b-form-input
                id="logintwofa"
                v-model="$v.form.logintwofa.$model"
                :state="validateState('logintwofa')"
                aria-describedby="input-logintwofa-feedback"
                autofocus
              ></b-form-input>
              <b-form-invalid-feedback id="input-logintwofa-feedback">{{
                $t("VALIDATION.REQUIRED_FIELD")
              }}</b-form-invalid-feedback>
            </b-col>
          </b-row>
        </b-container>
        <template v-slot:modal-footer>
          <b-button variant="outline-secondary" v-on:click="modalClose('twofa')">
            {{ $t("COMMON.CANCEL") }}
          </b-button>
          <b-button variant="primary" v-on:click="checkTwofa()">
            {{ $t("AUTH.LOGIN.BUTTON") }}
          </b-button>
        </template>
      </b-modal>
      <!--end::twofa-->
      <!-- ----------- forgot password modal -->
      <b-modal
        id="forgot-modal"
        ref="forgotPassword"
        :title="$t('AUTH.FORGOT.TITLE')"
      >
        <b-container fluid>
          <b-alert show variant="secondary">
            {{ $t("AUTH.FORGOT.DESC") }}
          </b-alert>
          <b-row class="my-3">
            <b-col sm="5">
              <label for="fusername">{{ $t("AUTH.INPUT.USERNAME") }}</label>
            </b-col>
            <b-col sm="7">
              <b-form-input
                id="fusername"
                v-model="$v.form.fusername.$model"
                :state="validateState('fusername')"
                :placeholder="$t('AUTH.INPUT.USERNAME')"
                aria-describedby="input-fusername-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-fusername-feedback">{{
                $t("AUTH.VALIDATION.USERNAME")
              }}</b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="my-3">
            <b-col sm="5">
              <label for="femail">{{ $t("AUTH.INPUT.EMAIL") }}</label>
            </b-col>
            <b-col sm="7">
              <b-form-input
                id="femail"
                v-model="$v.form.femail.$model"
                :state="validateState('femail')"
                :placeholder="$t('AUTH.INPUT.EMAIL')"
                aria-describedby="input-femail-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-femail-feedback">{{
                $t("AUTH.VALIDATION.EMAIL")
              }}</b-form-invalid-feedback>
            </b-col>
          </b-row>
        </b-container>
        <template v-slot:modal-footer>
          <b-button variant="outline-secondary" v-on:click="modalClose('forgotPassword')">
            {{ $t("COMMON.CANCEL") }}
          </b-button>
          <b-button variant="primary" v-on:click="forgotPassword()">
            {{ $t("AUTH.FORGOT.SEND") }}
          </b-button>
        </template>
      </b-modal>
      <!-- ---------- change password modal end------------- -->
    </div>
    <!--end::Body-->
  </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN_STEP1, LOGIN_STEP2 } from "@/store/auth.module";
import JwtService from "@/common/jwt.service";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import { postRequest } from "@/api/request.js";
import KTDropdownLanguage from "@/views/theme/topbar/DropdownLanguage.vue";
import i18nService from "@/common/i18n.service.js";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      form: {
        email: "",
        password: "",
        fusername: "",
        femail: "",
        logintwofa: "",
        token: "",
      },
      languageFlag: "",
      languages: i18nService.languages,
    };
  },
  components: {
    KTDropdownLanguage,
  },
  validations: {
    form: {
      email: {
        required,
        // email
      },
      password: {
        required,
        minLength: minLength(3),
      },
      fusername: {
        required,
      },
      femail: {
        required,
        email,
      },
      logintwofa: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    modalClose(item) {
      this.form.fusername = null;
      this.form.femail = null;
      this.form.logintwofa = null;
      this.form.token = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.$refs[item].hide();
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.email.$anyError || this.$v.form.password.$anyError) {
        return;
      }

      const username = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      // this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add(
        "kt-spinner",
        "kt-spinner--light",
        "kt-spinner--right"
      );

      this.$store
        .dispatch(LOGIN_STEP1, {
          username,
          password,
          action: "adminlogin_step1"
        })
        // go to which page after successfully login
        .then(res => {
          if (
            res.returncode == 1 &&
            !res.returndata.otprequired &&
            res.returndata.token
          ) {
            this.completeLogin(res.returndata.token);
          } else if (res.returndata.otprequired) {
            // show 2fa window
            this.form.token = res.returndata.token;
            this.$refs["twofa"].show();
          }
        });

      submitButton.classList.remove(
        "kt-spinner",
        "kt-spinner--light",
        "kt-spinner--right"
      );
    },
    completeLogin(token) {
      postRequest({
        action: "getAdminInfo",
        token: token
      }).then(resUser => {
        if (resUser && resUser.returncode) {
          JwtService.saveLoginUser(resUser.returndata.adminname);
          JwtService.saveTfaState(resUser.returndata.twofa_enabled);
          JwtService.saveTfaAllowedState(resUser.returndata.twofa_allowed);
          JwtService.saveSecurityWarningState(
            resUser.returndata.security_warning
          );
          JwtService.saveSecurityWarningLevel(
            resUser.returndata.security_warning_level
          );
          JwtService.saveUserRole(resUser.returndata.adminaccess);
          JwtService.saveTimeout(resUser.returndata.ui_timeout);
          const langCode = resUser.returndata.language;
          if (langCode == "pt-br") {
            i18nService.setActiveLanguage("ptbr");
          } else {
            i18nService.setActiveLanguage(langCode);
          }
          postRequest({
            action: "getPermissions",
            token: token
          }).then(adminPermission => {
            if (adminPermission && adminPermission.returncode) {
              JwtService.savePermission(adminPermission.returndata);
            }
            this.$router.push("home", () => this.$router.go(0));
          });
        }
      });
    },
    forgotPassword() {
      this.$v.form.$touch();
      if (this.$v.form.fusername.$anyError || this.$v.form.femail.$anyError) {
        return;
      }
      postRequest({
        action: "resetAdminPassword",
        username: this.form.fusername,
        emailadmin: this.form.femail,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.modalClose("forgotPassword");
      });
    },
    checkTwofa() {
      this.$v.form.$touch();
      if (this.$v.form.logintwofa.$anyError) {
        return;
      }
      const username = this.$v.form.email.$model;
      const otpcode = this.form.logintwofa;
      const token = this.form.token;

      this.$store
        .dispatch(LOGIN_STEP2, {
          username,
          otpcode,
          token,
          action: "adminlogin_step2"
        })
        .then(res => {
          if (res.returncode == 1 && res.returndata) {
            this.completeLogin(res.returndata);
          } else {
            this.makeToastVariant("danger", this.$t("AUTH.LOGIN.TFA_INVALID"));
          }
        });
    },
    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    },
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
  },
};
</script>
